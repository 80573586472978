<!-- eslint-disable -->
<template>
  <loading :active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card radius-lr20">
          <div class="card-header card-default">
            <div class="row">
              <div class="col-md-7">
                <p id="idExsampel" class="text-muted" >
                  Kartu Hasil Studi Mahasiswa
                </p>
              </div>
              <div class="col-md-5">
                <select class="selectTahun form-control form-control-rounded50" data-placeholder="Piliih Tahun Akademik" data-allow-clear="1"
                  v-model="dataSMT.smt_id">
                  <option v-for="smt in tahunAkademik" v-bind:value="smt.smt_id" :key="smt.smt_id">
                    {{ smt.smt_nama }}
                  </option>
                </select>
              </div>
            </div>
            
          </div>
          <div class="card-body padding-t-0 " >
            <div class="widget white-bg">
              <div class="row">
                <div class="col-md-3 col-xs-6 b-r"> <strong>{{ profil.nama_mahasiswa }}</strong>
                  <br>
                  <p class="text-muted">{{ userData.username }}</p>
                </div>
                <div class="col-md-4 col-xs-6 b-r"> <strong>{{ profil.jenj_didik + ' ' + profil.nama_prodi }}</strong>
                  <br>
                  <p class="text-muted">T.A {{ dataSMT.smt_nm }}</p>
                </div>
                <div class="col-md-3 col-xs-6 b-r" > <strong>S K S : {{ jumlahSKS }}</strong>
                  <br>
                  <p class="text-muted">I P S &nbsp;: <span v-show="isShowNil">{{ nilaiIPS }}</span></p>
                </div>
                <div class="col-md-2 col-xs-6 b-r" v-show="isShowNil">
                  <button type="button" v-on:click="downloadKHS"  class="btn btn-warning btn-rounded btn-sm btn-icon"><i class="fa fa-cloud-download"></i> Download &nbsp;</button> &nbsp;
                </div>
              </div>
            </div>
            
            <table class="table" v-show="isShowNil">
              <thead>
                <tr>
                  <th>#</th>
                  <th>KODE</th>
                  <th>NAMA MATAKULIAH</th>
                  <th>S K S</th>
                  <th>HM</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(krs, num) in dataKRS" :key="krs.id_nil">
                  <td>{{ num + 1 }}</td>
                  <td>{{ krs.kd_mak }}</td>
                  <td>{{ krs.nm_mak }}</td>
                  <td>{{ krs.mk_sks }}</td>
                  <td>{{ krs.mk_nil }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <template v-if="!isLoading">
            <div class="card-body padding-t-0" v-if="!isShowNil">
              <div class="alert bg-danger alert-dismissible" role="alert"> <button type="button" class="close"
                  data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>{{ isMessages }} </div>
            </div>
          </template>
        </div>
      </div>
    </div>
</template>
<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
/* global $ */
import Loading from 'vue-loading-overlay'
import { useStore } from 'vuex'
import { getMhs, getSMT } from '@/services/mahasiswa.service'
import { getKHS, cetKHS } from '@/services/khs.service'
import { getEdom } from '@/services/edom.service'
import { defineComponent, computed, reactive, toRefs, ref } from 'vue'
export default defineComponent({
  name: 'RiwayatKhs',
  components: {
    Loading
  },
  setup () {
    const store = useStore()
    const users = store.getters.user
    const state = reactive({
      profil: [],
      smt: {},
      params: { smt_id: '' },
      tahunAkademik: [],
      dataKRS: [],
      numKuisEdom: 0,
      isError: ref(false),
      isMessages: '',
      loadThnAkademik: false,
      loadEdomMhs: false,
      loadKhsMhs: false,
      loadMhs: false
    })
    const handleMahasiswa = () => {
      state.loadMhs = true
      getMhs({ nipd: users.username }).then(response => {
        state.profil = response?.records || []
        state.loadMhs = false
      })
    }
    const handleThAkademik = () => {
      state.loadThnAkademik = true
      getSMT({}).then(response => {
        state.smt = response?.record || {}
        state.params = { smt_id: response.record.smt_id }
        state.tahunAkademik = response?.records || []
        state.loadThnAkademik = false
      })
    }
    const sourceMahasiswa = computed(() => {
      return state.profil
    })
    const sourceSemester = computed(() => {
      return state.smt
    })

    const jumlahSKS = computed(() => {
      var totKredit = 0
      const entriMHS = state.dataKRS
      $.each(entriMHS, function (index, value) {
        totKredit = totKredit + Number(value.mk_sks)
      })
      return totKredit
    })

    const isLoading = computed(() => {
      const load = !!((state.loadThnAkademik || state.loadEdomMhs || state.loadKhsMhs || state.loadMhs))
      return load
    })

    const isShowNil = computed(() => {
      const jumMK = state.dataKRS.length
      const jumEdom = state.numKuisEdom
      const isEdom = (jumEdom >= jumMK)
      return !!((isEdom && !state.isError))
    })

    return {
      ...toRefs(state),
      userData: users,
      khsVal: [],
      dataSMT: {},
      nilaiIPS: 0,
      fullPage: true,
      onClikTahun: false,
      errorData: {},
      handleMahasiswa,
      handleThAkademik,
      sourceMahasiswa,
      sourceSemester,
      jumlahSKS,
      isLoading,
      isShowNil
    }
  },
  created () {
    this.handleMahasiswa()
    this.handleThAkademik()
    this.handleKhs()
  },
  mounted () {
    var vm = this
    $('.selectTahun').select2({
      theme: 'bootstrap4',
      width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
      placeholder: $(this).data('placeholder'),
      allowClear: Boolean($(this).data('allow-clear'))
    }).on('select2:select', function (e) {
      vm.params.smt_id = e.params.data.id
      vm.onClikTahun = true
      vm.handleKhs()
    }).trigger('change')
    $('.scrollDiv').slimScroll({
      color: '#eee',
      size: '5px',
      height: '293px',
      alwaysVisible: false
    })
  },
  methods: {
    /*
    async dataMahasiswa () {
      this.loadMhs = true
      getMhs({ nipd: this.userData.username })
        .then((response) => {
          this.profil = response.records
          this.loadMhs = false
        })
        .catch((error) => {
          this.errorData = (error.response) ? {} : {}
          this.loadMhs = false
        })
    },
    */
    /*
    async getTahunAkademik () {
      this.loadThnAkademik = true
      getSMT({}).then(response => {
        if (response.error_code === 200) {
          this.tahunAkademik = response.records
          this.getData()
        }
        this.loadThnAkademik = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadThnAkademik = false
      })
    },
    */
    async getPrasarat () {
      this.loadEdomMhs = true
      const params = {
        id_reg_pd: this.userData.id_reg_pd,
        id_smt: this.params.smt_id
      }
      getEdom(params).then(response => {
        if (response.error_code === 200) {
          const row = response.records
          this.numKuisEdom = Object.keys(row).length
          this.loadEdomMhs = false
          this.isMessages = 'Maaf!. saudara belum mengisi Edom'
        }
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadEdomMhs = false
      })
    },
    async handledataKrs () {
      this.loadKhsMhs = true
      const params = {
        id_reg_pd: this.userData.id_reg_pd,
        th_smtmasuk: this.sourceMahasiswa.id_mulai_masuk_smt,
        id_smt: this.params.smt_id
      }
      getKHS(params).then(response => {
        this.dataSMT = response.tahun_akademik
        if (response.error_code === 200) {
          this.dataKRS = response.records
          this.isError = false
          this.statusKRS = response.status
          this.nilaiIPS = response.ips
        } else {
          this.isError = true
          this.dataKRS = []
          this.nilaiIPS = 0
          this.isMessages = response.messages
        }
        this.loadKhsMhs = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadKhsMhs = false
      })
    },
    async handleKhs () {
      this.getPrasarat()
      this.handledataKrs()
    },
    async downloadKHS () {
      this.isLoadCET = true
      cetKHS({ reg: this.profil.id_mahasiswa, smt: this.params.smt_id }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'KHS ' + this.profil.npm_mahasiswa + '.pdf')
        document.body.appendChild(fileLink)

        fileLink.click()
        this.isLoadCET = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoadCET = false
      })
    }
  }
})
</script>
